// 生产环境
let config = {
      baseUrl: 'http://localhost:9900', // 生产网关地址
    //   baseUrl: 'https://www.jsyxcloud.com/gateway', // 生产网关地址
    //   baseUrl: 'http://edu.jkxjx.com/gateway', // 测试环境
    //   baseUrl: 'http://192.168.31.245:9900', // 本地
      tokenName: 'EDU_WEB_TOKEN',
      tokenExpires: 60 * 60 * 1000 // token 过期时间为1个小时
    }
    console.log('========process.env.NODE_ENV',process.env.NODE_ENV)
    if (process.env.NODE_ENV === 'development') {
      // 开发环境
      config = {
        ...config,
        // baseUrl: 'http://1495572712942522369.roncoo.com/gateway', // 开发网关地址
        // url: '1495572712942522369.roncoo.com' // 店铺域名，本地开发使用，生产不用配置该属性
        baseUrl: 'http://192.168.31.245:9900', // 开发网关地址
        url: 'edu.jtymzy.com' // 店铺域名，本地开发使用，生产不用配置该属性
      }
    }
    export default config
    